import React from 'react';

import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
import { flatten } from 'lodash';

import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import { commonPropTypes, reactionWithOldValue } from './utils';
import keyboard from '~static/js/base/keyboard';
import { Loader } from '~static/file-viewer/panorama/loader';
import { UploadFolder, get as getUploader } from '~static/js/upload/upload';
import { progressTracker } from '~static/js/progress/progress';
import pubsub from '~static/js/base/pubsub';
import UnityVGXViewerController from './Controllers/UnityVGXViewerController';
import { DefaultController } from './Controllers/Default';
import { requestDeepLink } from '../../nomad/ShowInNomad';
import { queryString } from '~static/js/lib';
import { request } from '../../base';
import fullScreen from '../../base/fullscreen';
import { isLinkView } from '../../preview/link';

const urlCreator = window.URL || window.webkitURL;

const NotEnoughMemory = () => (
    <div className='message-box flex-col'>
        <span className='icon icon-missing-3d-capabilities' />
        <div className='message'>
            <span className='msg-row-prim'>{ gettext('Large 3D model') }</span>
            <span>
                {
                    reactStringReplace(
                        gettext('Due to browsers’ memory usage limitations, this model cannot be loaded. If you have a late model device with good memory, try viewing the model in the Vectorworks Nomad mobile app. If the model is still too large, reduce the size by hiding objects or using the clip cube (see this %(helpArticleTitle)s for details).'),
                        '%(helpArticleTitle)s',
                        () => <a
                            key='help-link-model-optimization'
                            href='/portal/help/faq/troubleshooting/#how-can-i-optimize-my-model-for-viewing-in-ar-or-3d'
                        >{ gettext('FAQ') }
                        </a>
                    )
                }
            </span>
        </div>
    </div>);

const UnknownError = () => (
    <div className='message-box flex-col'>
        <span className='icon icon-missing-3d-capabilities' />
        <div className='message'>
            <span className='msg-row-prim'>{ gettext('Unknown error') }</span>
            <span>
                { gettext('An unknown error has occurred. Please contact the system administrator for assistance.') }
            </span>
        </div>
    </div>);

class UnityVGXViewer extends React.Component {
    name = 'UnityVGXViewer';
    keyEvents = null;
    savedViews = null;
    uploader = null;

    state = { error: null };

    controller = {
        state: observable.map(new Map()),
        togglePanel: name => {
            this.sendCommand('viewerController.togglePanel', name);
        },
        toggleNavigationMode: () => {
            this.sendCommand('viewerController.toggleNavigationMode');
        },
        goHome: () => {
            this.sendCommand('viewerController.goHome');
        },
        showNextSavedView: () => {
            this.sendCommand('viewerController.showNextSavedView');
        },
        exitPresentationMode: () => {
            this.sendCommand('viewerController.exitPresentationMode');
        },
        showPreviousSavedView: () => {
            this.sendCommand('viewerController.showPreviousSavedView');
        },
        initState () {
            runInAction(() => {
                this.state.set('currentPanel', null);
                this.state.set('navigationMode', 'orbit');
                this.state.set('presentationMode', { active: false });
                this.state.set('showToolbar', true);
                this.state.set('enableHome', true);
            });
        }
    };

    componentDidMount () {
        this.controller.initState();
        this.loadSavedViews();

        this.reactionDisposer = reactionWithOldValue(
            () => this.props.store.file.file,
            (newValue, oldValue) => {
                this.savedViews = null;
                if (!oldValue || oldValue.versionId !== newValue.versionId) {
                    this.loadSavedViews();
                }
            },
            { name: 'endLoadingOnChangedFile' }
        );
        this.keyEvents = keyboard.peekKeyEvents();
        this.keyEvents.extend({
            left: {
                predicate: event => event.which === 37,
                handler: () => {
                    this.controller.state.get('presentationMode')?.active && this.controller.showPreviousSavedView();
                }
            },
            right: {
                predicate: event => event.which === 39,
                handler: () => {
                    this.controller.state.get('presentationMode')?.active && this.controller.showNextSavedView();
                }
            }
        });

        window.document.addEventListener('UnityVGXViewerEvent', this.onViewerEvent);
    }

    componentWillUnmount () {
        this.keyEvents && this.keyEvents.reduce();
        this.keyEvents = null;
        this.reactionDisposer && this.reactionDisposer();
        window.document.removeEventListener('UnityVGXViewerEvent', this.onViewerEvent);
        this.controller.initState();
    }

    getSavedViews = () => {
        return new Promise((resolve) => {
            const asset = this.props.store.file.file;
            const allVersions = asset.allVersions();
            const currVerIndx = allVersions.findIndex(v => v.versionId === asset.versionId);
            const prevVersionsLookupRelated = flatten(allVersions.slice(currVerIndx).map(a => a.relatedFiles()));
            let savedViews = prevVersionsLookupRelated.find(asset => asset.fileType.type === 'vgx_views');
            const canEdit = asset.hasPermission?.('modify') ?? false;

            const { uuid: linkUuid } = isLinkView();
            if (!savedViews && linkUuid) {
                savedViews = {
                    downloadUrl: `/restapi/public/v2/shareable_link/${linkUuid}/file/:related/internal/p:${asset.prefix.replace('.vgx', '.savedviews')}/`,
                    name: `${asset.baseName}.savedviews`
                };
            }

            if (savedViews) {
                const loader = new Loader();
                loader.setResponseType('blob');
                loader.crossOrigin = '';

                loader.load(
                    queryString.buildUrl(savedViews.downloadUrl, { download: 'on' }),
                    blob => {
                        resolve({
                            filename: savedViews.name,
                            url: urlCreator.createObjectURL(blob),
                            canEdit
                        });
                    },
                    () => {}, // onProgress
                    () => resolve({ canEdit })
                );
            } else {
                resolve({ canEdit });
            }
        });
    };

    loadSavedViews = () =>
        this.getSavedViews()
            .then(savedViews => {
                this.savedViews = savedViews;
                this.props.store.endLoading();
            });

    onViewerEvent = e => {
        const { name, data } = e.detail;

        if (name === 'viewerController.requestResources') {
            // wait for saved views to be loaded
            this.interval = setInterval(() => {
                if (this.savedViews) {
                    clearInterval(this.interval);
                    this.sendCommand('viewerController.openFiles', {
                        modelUrl: this.props.sourceUrl,
                        savedViews: this.savedViews
                    });
                }
            });
        }

        if (name.startsWith('viewerController.toolbar.')) {
            runInAction(() => {
                this.controller.state.set('showToolbar', name.endsWith('show'));
            });
        }

        if (name.startsWith('viewerController.home.')) {
            runInAction(() => {
                this.controller.state.set('enableHome', name.endsWith('enabled'));
            });
        }

        if (name === 'viewerController.state') {
            runInAction(() => {
                this.controller.state.set('currentPanel', data.currentPanel);
                this.controller.state.set('navigationMode', data.navigationMode);
                if (data.navigationMode === 'walkthrough') {
                    setTimeout(() => this.iframe.contentWindow.focus(), 100);
                }
            });
        }

        if (name.startsWith('viewerController.savedviews')) {
            this.handleSavedViewsEvent(name, data);
        }

        if (name === 'error') {
            this.props.store.endLoading();
            this.setState({ error: data });
        }
    };

    sendCommand = (command, data) => {
        const event = new window.CustomEvent(
            'UnityVGXViewerCommand',
            { detail: { command, data } }
        );
        this.iframe.contentDocument.dispatchEvent(event);
    };

    onIframeLoad = () => {
        const iframeSrc = this.iframe.getAttribute('src');
        if (iframeSrc.startsWith(window.location.origin)) {
            $(this.iframe).contents().find('body').on('mousemove', this.props.store.ui.controller.show);
        }
    };

    getIframeSrc = () => {
        const src = Settings.offlinePresentation
            ? 'app/file-viewer/unity-vgx/vgx-template.html?embedded=1'
            : `${window.location.origin}/portal/file-viewer/unity-vgx/?embedded=1`;
        return src;
    };

    getUploader = () => {
        return this.uploader
            ? Promise.resolve(this.uploader)
            : getUploader({
                bucket: 'internal',
                resourceType: 'web_upload',
                progressTracker
            })
                .then(uploader => {
                    this.uploader = uploader;
                    return uploader;
                });
    };

    handleSavedViewsEvent = (name, data) => {
        if (name === 'viewerController.savedviews.updated') {
            this.updateSavedViewsFile(new Blob([data], { type: 'application/json' }));
        } else if (name === 'viewerController.savedviews.presentation_mode.opened') {
            runInAction(() => {
                this.controller.state.set('presentationMode', { active: true, list: JSON.parse(data).savedViewsList });
                fullScreen.request($('.viewer-wrapper').get(0));
                this.props.store.ui.fullScreen = true;
            });
        } else if (name === 'viewerController.savedviews.presentation_mode.show_page_name') {
            runInAction(() => {
                const state = this.controller.state.get('presentationMode');
                this.controller.state.set('presentationMode', { ...state, current: JSON.parse(data).currentSavedView });
            });
        } else {
            console.warn('Unknown saved views event:', name);
        }
    };

    exitPresentationMode = () => {
        this.controller.exitPresentationMode();
        fullScreen.exit($('.viewer-wrapper').get(0));
        runInAction(() => {
            this.controller.state.set('presentationMode', { active: false });
        });
    };

    updateSavedViewsFile = file => {
        const asset = this.props.store.file.file;
        this.getUploader()
            .then(uploader => {
                const folder = UploadFolder.fromAssets(
                    asset.folder,
                    asset.ownerInfo,
                    [],
                    { storageType: asset.storageType }
                );
                const filename = this.savedViews?.filename || `${asset.baseName}.savedviews`;
                pubsub.subscribe(this, 'upload.finished', data => {
                    if (data.name === filename) {
                        pubsub.unsubscribeAll(this);
                        this.relateSavedViewsFile(data.resource_uri);
                    }
                });
                uploader.startNamedUpload(file, filename, folder);
            });
    };

    relateSavedViewsFile = related => {
        const asset = this.props.store.file.file;
        request({
            url: asset.resourceUri,
            method: 'PATCH',
            data: { related_files: [related] },
            contentType: 'application/json; charset=utf-8'
        })
            .then(() => {})
            .catch(console.error);
    };

    render () {
        const { error } = this.state;
        const panel = this.controller?.state.get('currentPanel');
        const toolbar = this.controller?.state.get('showToolbar');

        return !this.state.error
            ? <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    { this.props.children }

                    {
                        !this.props.store.loading &&
                            <iframe
                                className='file-view-iframe'
                                ref={iframe => { this.iframe = iframe; }}
                                title={this.props.store.file.name}
                                src={this.getIframeSrc()}
                                onLoad={this.onIframeLoad}
                                allowFullScreen
                            />
                    }
                </div>
                {
                    window.Settings.device.isMobile || (document.querySelector('.viewer-wrapper')?.clientWidth < 1020)
                        ? (!panel || ['clipCube'].includes(panel) || toolbar) && <UnityVGXViewerController {...this.props} viewer={this}/>
                        : (toolbar && <UnityVGXViewerController {...this.props} viewer={this}/>)
                }
            </React.Fragment>
            : <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    {
                        error && (
                            error.message?.includes('out of bounds')
                                ? <NotEnoughMemory />
                                : <UnknownError />
                        )
                    }
                </div>
                <DefaultController {...this.props} />
            </React.Fragment>;
    };
};

class OpenInNomadOnMobile extends React.Component {
    componentDidMount () {
        this.props.store.endLoading();
    }

    handleOpenInNomad = () => {
        const resourceId = this.props.store.file.file.data.resource_id;
        const params = new URLSearchParams(window.location.search);
        params.append('file.resourceId', resourceId);

        const l = window.location;
        const url = l.origin + l.pathname + '?' + params.toString();

        requestDeepLink(url)
            .then(({ shortLink }) => {
                if (shortLink) {
                    window.location.href = shortLink;
                }
            })
            .catch(() => {});
    };

    render () {
        return (
            <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    <div className='message-box flex-col'>
                        <span className='icon icon-missing-3d-capabilities' />
                        <div className='message'>
                            <span className='msg-row-prim'>{ gettext('Unsupported browser') }</span>
                            <span className='msg-row-sec'>
                                {gettext('Vectorworks 3D models can\'t be viewed in mobile browsers due to browsers\' technical limitations. Get the free Vectorworks Nomad mobile app.')}
                            </span>
                            <br/>
                            <Button variant='primary' onClick={this.handleOpenInNomad}>
                                {gettext('Open in Nomad')}
                            </Button>
                        </div>
                    </div>

                    { this.props.children }
                </div>
                <DefaultController {...this.props} />
            </React.Fragment>
        );
    }
}

OpenInNomadOnMobile.propTypes = {
    ...commonPropTypes
};

const OpenInNomad = observer(OpenInNomadOnMobile);

UnityVGXViewer.propTypes = {
    ...commonPropTypes,
    sourceUrl: PropTypes.string
};

export default observer(UnityVGXViewer);
export { OpenInNomad };
