import React from 'react';

import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { toggleClass, hasClass } from '../../../../lib/dom-utils';
import fullScreen from '../../../../base/fullscreen';

const FullScreen = ({ store }) => {
    React.useEffect(() => {
        fullScreen.on('change', handleUnexpedtedFullscreenExit);

        return () => {
            fullScreen.off('change', handleUnexpedtedFullscreenExit);
        };
    }, []);

    const togglePortalFVFullscreen = action(() => {
        fullScreen.toggle($('.viewer-wrapper').get(0));
        store.ui.fullScreen = !store.ui.fullScreen;
    });

    const toggleBoardsFVFullscreen = action(() => {
        const isFullscreen = store.root.isFullscreen;
        if (isFullscreen) {
            const fileView = document.querySelector('.file-view');
            toggleClass(fileView, 'fake-fs');
            store.ui.fullScreen = hasClass(fileView, 'fake-fs');
        } else {
            fullScreen.toggle($('.viewer-wrapper').get(0));
            store.ui.fullScreen = !store.ui.fullScreen;
        }
    });

    const toggleFullscreen = () =>
        store.isPortalFV
            ? togglePortalFVFullscreen()
            : toggleBoardsFVFullscreen();

    const handleUnexpedtedFullscreenExit = () => {
        setTimeout(() => {
            if (store.ui.fullScreen !== fullScreen.isFullscreen) {
                runInAction(() => {
                    store.ui.fullScreen = fullScreen.isFullscreen;
                });
            }
        }, 100);
    };

    const tooltipText = store.ui.fullScreen ? gettext('Exit full screen') : gettext('Enter full screen');

    return (
        <span
            className={`ctrl-tool icon ${store.ui.fullScreen ? 'icon-exit-full-screen' : 'icon-full-screen'}`}
            onClick={toggleFullscreen}
            title={gettext(tooltipText)}
            ga-action='File_View_Controller'
            ga-label='Click_Toggle_Fullscreen'
            data-what='controller-tool'
            data-which='toggle-fullscreen'
        />
    );
};

FullScreen.propTypes = {
    store: PropTypes.object
};

export default observer(FullScreen);
