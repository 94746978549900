import React, { lazy, useCallback, useRef, Suspense } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { waitForNode } from '../../lib/utils';
import BrandingLogo from '../../branding/BrandingLogo';
import KeyHandler from '../../Components/KeyHandler';
import { pubsub } from '../../base';
import { queryString } from '../../lib';
import { CommentsContent } from '../Sections/Comments';
import { DefaultController } from './Controllers/Default';

const Viewer = lazy(() => import('@vectorworks/vcs-pdf-viewer').then(mod => ({ default: mod.Viewer })));

const VCDOCViewer = ({ store }) => {
    const contentRef = React.useRef();
    const viewerRef = useRef({});
    const [sourceUrl, setSourceUrl] = React.useState(null);

    const [showBranding, setShowBranding] = React.useState(null);
    const [bypassEvents, setBypassEvents] = React.useState(false);
    const commentsStore = store.file.commentsStore;

    React.useEffect(() => {
        getDocumentSourceUrl();
        commentsStore.load();
    }, [store.file.sourceUrl]);

    React.useEffect(() => {
        pubsub.subscribe(
            {},
            'keyboard.shallow',
            ({ events }) => { setBypassEvents(events.id !== 'PDF_VIEWER'); }
        );
        return () => { pubsub.unsubscribe({}, 'keyboard.shallow'); };
    }, []);

    React.useEffect(() => {
        waitForNode(() => document.getElementById('vcs-pdf-viewer__content'))
            .then((node) => {
                contentRef.current = node;
                contentRef.current.addEventListener('mousemove', store.ui.controller.show);
                setShowBranding(true);
            });

        return () => {
            contentRef.current.removeEventListener('mousemove', store.ui.controller.show);
            setShowBranding(false);
        };
    }, []);

    const getDocumentSourceUrl = () => {
        if (sourceUrl !== store.file.sourceUrl) {
            store.startLoading();
            const pdfPromise = Settings.offlinePresentation
                ? Promise.resolve(store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    store.file.sourceUrl,
                    { response_type: 'data', viewable: 'off' }
                ));
            pdfPromise
                .then(data => {
                    setSourceUrl(data.url);
                    store.endLoading();
                })
                .catch(err => {
                    if (err?.status === 404) {
                        store.file.setNotFoundFile();
                    }
                });
        }
    };

    const onCommentDrawEnd = useCallback((commentGeometry) => {
        commentsStore.setFocusedComment(null);
        commentsStore.setTempCommentGeometry(commentGeometry);

        // Might need to change tabs, render the comment field etc.
        setTimeout(() => {
            const target = document
                .getElementById(
                    commentsStore.showAllComments
                        ? `vcsPDF-viewer-comments-${commentsStore.visiblePageNumber}`
                        : 'post-comment-field'
                );

            const container = document.getElementById('comments-content');
            if (!target || !container) return;

            const targetOffset = target.getBoundingClientRect().top;
            const contentOffset = container.getBoundingClientRect().top;
            const offset = targetOffset - contentOffset;
            const top = container.scrollTop + offset;

            container.scrollTo({
                top,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    const onCommentDrawingClick = useCallback((geometry) => {
        // If you have drawn a comment, you can't focus others
        commentsStore.setTempCommentGeometry(null);

        const comment = commentsStore.comments.find(c => c.id === geometry.id);
        commentsStore.setFocusedComment(comment);
        setTimeout(() => {
            try {
                comment.ref.current.scrollIntoView({ behavior: 'smooth' });
            } catch {
                console.warn("Could't scrollIntoView comment id: " + comment.id);
            }
        }, 100); // In case the tab needs to be changed
    }, []);

    const onPageChange = useCallback((visiblePages) => {
        if (visiblePages?.length > 0) {
            commentsStore.setVisiblePages(visiblePages);
        }
    }, []);

    const onPagesLoad = useCallback(async (pagesMap) => {
        commentsStore.setPages(pagesMap);
        commentsStore.vcsPDFViewer = viewerRef.current;
        if (store.ui.comments) {
            viewerRef.current.store.setTab(1);

            if (!commentsStore.allowComments) {
                const nodes = await waitForNode(() => document.getElementsByClassName('viewer-wrapper'));
                if (nodes.length === 0) return;
                Array
                    .from(nodes[0].getElementsByTagName('button'))
                    .filter(b => b.title === 'Comment')
                    .forEach(b => { b.style.display = 'none'; });
            }
        }
    }, []);

    return (
        <Suspense fallback={null}>
            <KeyHandler keyEvents={{ id: 'PDF_VIEWER' }} />
            <Viewer
                viewerRef={viewerRef}
                fileName={sourceUrl}
                bypassEvents={bypassEvents}
                onModeChange={() => { }}
                api={{
                    comments: {
                        list: () => Promise.resolve([])
                    }
                }}
                onCommentDrawEnd={onCommentDrawEnd}
                onCommentDrawingClick={onCommentDrawingClick}
                onPageChange={onPageChange}
                onPagesLoad={onPagesLoad}
                setFocusedComment={c => commentsStore.setFocusedComment(c)}
                setTempCommentGeometry={c => commentsStore.setTempCommentGeometry(c)}
                commentsGeometries={commentsStore.commentGeometries}
                focusedCommentGeometry={
                    commentsStore.tempCommentGeometry ||
                    commentsStore.focusedCommentGeometry
                }
                commentsPanelChildren={<CommentsContent store={store} />}
            />
            {showBranding && (
                createPortal(
                    <BrandingLogo branding={store.file.file.ownerInfo?.branding} />,
                    contentRef.current
                )
            )}
            <DefaultController store={store} />
        </Suspense>
    );
};

export default observer(VCDOCViewer);

VCDOCViewer.propTypes = {
    store: PropTypes.object
};
