import { toast } from '../toast';
import { buildPreviewPath } from '../lib';
import { filePreview } from '../preview/file-preview.vm';
import { StorageProvider } from '../browser/storage-providers';
import { pubsub } from '../base';
import { UploadStatus } from './utils';

async function getUploader (params) {
    const uploader = await import('./uploader');
    return new uploader.Uploader(Settings.uploader, params).init();
}

class UploadFolder {
    static fromAssets (path, ownerInfo, assets, ops = {}) {
        return new UploadFolder(
            decodeURI(path.replace(/\/*$/, '')),
            ownerInfo,
            assets.map(asset => {
                return { name: asset.name, isFolder: asset.isFolder };
            }),
            ops
        );
    }

    constructor (path, ownerInfo, nodes, ops = {}) {
        this.path = path;
        this.ownerInfo = ownerInfo;
        this.nodes = nodes;
        this.ops = ops;
    }

    fullPath (fileName) {
        return this.path + '/' + fileName;
    }

    find (name) {
        return ko.utils.arrayFirst(this.nodes, node => node.name === name);
    }

    uploadPrefix () {
        const mountPoint = this.ownerInfo.mountPoint;
        return (
            mountPoint
                ? mountPoint.prefix
                : this.ownerInfo.hasPermission('modify')
                    ? this.ownerInfo.uploadPrefix
                    : Settings.user.uploadPrefix
        ) + (this.storageType ? `/${this.storageType}` : '');
    }

    uploadPath (fileName) {
        const storageType = this.ops?.storageType;
        return `${this.uploadPrefix()}${storageType ? '/' + storageType : ''}${this.path ? '/' + this.path : ''}/${fileName}`;
    }

    namespace () {
        return `${this.uploadPrefix()}:${this.path ? this.path : '%root'}`;
    }
}

class UploadProcess {
    constructor (id, uploader, file, status = null) {
        this.id = id;
        this.uploader = uploader;
        this.file = file;
        this.name = file.name;
        this.path = 'Home / ' + file.formatedPath();
        this.size = file.formatedSize();
        this.status = ko.observable(status || UploadStatus.pending);
        this.percentComplete = ko.observable(0);
        this.progress = ko.observable('');
        this.icon = 'icon icon-upload';
        this.isCompleted = ko.pureComputed(() => this.status().completed);
        this.error = ko.observable(false);
        this.canceled = ko.observable(false);
        this.canCancel = ko.pureComputed(() => !this.status().completed);
        this.lastUpdate = ko.observable('');

        this.storageProvider = StorageProvider.S3;
        this.previewPath = buildPreviewPath([
            this.storageProvider.displayName,
            this.file.folder
        ]);

        this.type = 'upload';
        this.what = 'upload';
        this.which = this.name;
    }

    start () {
        this.status(UploadStatus.uploading);
        this.progress('0%');
        this.percentComplete(0);
    }

    cancel () {
        this.canceled(true);
        this.uploader.cancelFileUpload(this.id);
        this.status(UploadStatus.canceled);
    }

    updateProgress (currentProgress) {
        this.percentComplete(currentProgress > 0 ? currentProgress - 1 : currentProgress);
        this.progress(currentProgress > 0 ? (currentProgress - 1 + '%') : currentProgress + '%');
    }

    finish (asset) {
        this.asset = asset;
        this.status(UploadStatus.uploaded);
        this.percentComplete(100);
        this.progress(100 + '%');
        toast('UPLOAD_COMPLETE');
    }

    setErrorStatus () {
        this.status(UploadStatus.failed);
        this.error(true);
        toast('UPLOAD_FAIL');
    }

    view (uploadProcess, event) {
        pubsub.publish('key.escape');
        const asset = uploadProcess.asset;
        filePreview.openFromAsset(asset, [asset]);
        Dialog.dismiss(); // eslint-disable-line
    };
}

export {
    getUploader as get,
    UploadFolder,
    UploadStatus,
    UploadProcess
};
