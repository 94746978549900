import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import withResponsiveListeners from '../../../../utils/Responsive';

import { TABLES, COLUMNS } from './utils';
import S from './styles';
import Circuit from './Circuit';
import Equipment from './Equipment';
import Device from './Device';

const ColumnTitle = observer(({ col }) => {
    const onClick = () => col.toggleSort();
    const title = col.unit
        ? `${col.title} (${col.unit.title})`
        : col.title;

    return (
        <S.th
            key={col.id}
            onClick={onClick}
            cellType={COLUMNS[col.id].type}
        >
            <span style={{ fontSize: 15 }}>{ title || col.id }</span>
            {
                col.sort &&
                <Icon
                    icon={col.sort === 'ASC' ? 'sort-down' : 'sort-up'}
                    size='xs'
                />
            }
        </S.th>
    );
});

const Head = observer(({ store }) => {
    const { columns } = store;
    return (
        <S.Head className='hidden-xs hidden-sm' count={columns.activeCount}>
            { columns.active.map(c => <ColumnTitle key={c.title} col={c}/>) }
        </S.Head>
    );
});

Head.propTypes = {
    store: PropTypes.object
};

const tableMap = {
    circuits: Circuit,
    equipment: Equipment,
    devices: Device
};

const Body = withResponsiveListeners(observer(({ store, isDevices, entries, screen }) => {
    const View = tableMap[store.table];
    const bodyRef = React.useRef();
    const isMobile = !['md', 'lg'].includes(screen.size);

    React.useEffect(() => {
        // reset horizontal scroll
        document.getElementsByClassName('ccad-component-loader')[0].scrollTo(0, 0);
        // reset vertical scroll
        bodyRef?.current?.scrollTo(0, 0);
    }, [store.table]);

    return (
        <S.Body ref={bodyRef} isMobile={isMobile} count={store.columns.activeCount}>
            {
                entries.map(r =>
                    <View
                        key={r.key || r[0].key}
                        item={isDevices ? r[0] : r}
                        sockets={isDevices ? r : []}
                        store={store}
                    />
                )
            }
        </S.Body>
    );
}));

class Table extends React.Component {
    get devices () {
        const { records } = this.props.store;
        const info = records.reduce((acc, r) => {
            (acc[r.device] = acc[r.device] || []).push(r);
            return acc;
        }, {});
        return Object.values(info);
    }

    render () {
        const { store } = this.props;
        const isDevices = store.table === TABLES.devices.name;
        const entries = isDevices
            ? this.devices
            : store.records;

        return (
            <React.Fragment>
                <Head store={store} />
                <Body store={store} isDevices={isDevices} entries={entries} />
            </React.Fragment>
        );
    }
};

Table.propTypes = {
    store: PropTypes.object
};

export default observer(Table);
