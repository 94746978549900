import React from 'react';
import { observer } from 'mobx-react';

import { pubsub } from '../../../../base';
import Circuit from './Circuit';
import Equipment from './Equipment';
import CollapsableRow from './CollapsableRow';
import ColumnFilterMenu from './Filters/ColumnFilters/ColumnFilterMenu';
import NestedRecord from './NestedRecord';
import ViewerContext from './ViewerContext';
import S from './styles';

const tableMap = {
    circuits: Circuit,
    equipment: Equipment
};

const Body = observer(() => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const View = tableMap[store.table];
    const bodyRef = React.useRef();

    React.useEffect(() => {
        pubsub.subscribe(Body, 'fv.ccad.back', () => {
            const { table, sourceItem, scrollPosition } = store.navigationHistory.goBack();
            if (table !== null) {
                setTimeout(() => {
                    sourceItem
                        ? pubsub.publish('fv.ccad.go.to', sourceItem)
                        : bodyRef?.current?.scrollTo(0, scrollPosition); // eslint-disable-line
                }, 500);
                pubsub.publish('fv.ccad.table.changed', {
                    table,
                    addToHistory: false
                });
            }
        });
        pubsub.subscribe(Body, 'fv.ccad.table.changed', ({ previousTable, sourceItem, addToHistory }) => {
            addToHistory && store.navigationHistory.add({
                table: previousTable,
                sourceItem,
                scrollPosition: bodyRef?.current?.scrollTop
            });
        });
        return () => pubsub.unsubscribeAll(Body);
    }, []);

    React.useEffect(() => {
        // reset horizontal scroll
        document.getElementsByClassName('ccad-component-loader')[0].scrollTo(0, 0);
        // reset vertical scroll
        bodyRef?.current?.scrollTo(0, 0);
    }, [store.table]);

    return (
        <S.Body
            ref={bodyRef}
            isMobile={isMobile}
        >
            {
                store.records.map(r =>
                    store.columns.hasNestedColumns
                        ? <NestedRecord
                            key={`${r.column}-${r.value}`}
                            record={r}
                            identation={0}
                        />
                        : store.columns.collapsable.length > 0
                            ? <CollapsableRow
                                key={`collapsable-${r.column}-${r.value}`}
                                row={r}
                                identation={0}
                            />
                            : <View
                                key={r.key}
                                item={r}
                            />
                )
            }
            {
                store.columns.active.map(c => <ColumnFilterMenu
                    key={`filter-menu-${c.id}`}
                    columnId={c.id}
                />)
            }
        </S.Body>
    );
});

export default Body;
