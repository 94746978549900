import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { disabled } from '@vectorworks/vcs-ui/dist/lib/styles/common';

import { Controller } from './Default';
import FullScreen from './Widgets/FullScreen';

const Toggler = observer(({ controller, title, icon, name }) =>
    <span
        className={`ctrl-tool icon icon-${icon} ${controller.state.get('currentPanel') === name ? 'active' : ''}`}
        onClick={() => controller.togglePanel(name)}
        title={title}
    />
);

Toggler.propTypes = {
    controller: PropTypes.object,
    title: PropTypes.string,
    prop: PropTypes.string
};

const NavigationMode = observer(({ controller }) => {
    const isWalkthrough = controller.state.get('navigationMode') === 'walkthrough';
    return (
        <span
            className={`ctrl-tool icon icon-3d-walkthrough ${isWalkthrough ? 'active' : ''}`}
            onClick={() => controller.toggleNavigationMode()}
            title={gettext('Walkthrough')}
        />
    );
});

NavigationMode.propTypes = {
    controller: PropTypes.object,
    title: PropTypes.string,
    prop: PropTypes.string
};

const S = {
    Navigation: styled.div`
        ${props => props.disabled && disabled}

        color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
    `,
    SideSections: styled.div`
        width: 50px;
        min-width: 50px !important;
        max-width: 50px !important;
        justify-content: center !important;
    `,
    SavedViewName: styled.div`
        height: 50px;
        width: auto;
        min-width: 160px;
        max-width: 200px;

        color: #fff;
        font-size: 12px;

        display: flex;
        align-items: center;
        justify-content: center;
    `
};

const UnityVGXPresentModeController = ({ store, viewer }) => {
    const savedViews = viewer.controller.state.get('presentationMode');
    const hasPrevious = savedViews?.list?.findIndex(view => view === savedViews.current) > 0;
    const hasNext = savedViews?.list?.findIndex(view => view === savedViews.current) < savedViews?.list?.length - 1;

    const exitPresentationMode = () => viewer.exitPresentationMode();
    const prevView = () => hasPrevious && viewer.controller.showPreviousSavedView();
    const nextView = () => hasNext && viewer.controller.showNextSavedView();

    return (
        <Controller store={store}>
            <S.SideSections className='control-section left'>
                <span
                    className='ctrl-tool icon icon-stop-presentation'
                    onClick={exitPresentationMode}
                    title={gettext('Exit present mode')}
                />
            </S.SideSections>

            <div className='control-section center'>
                <S.Navigation onClick={prevView} title={gettext('Previous')} disabled={!hasPrevious}>
                    <Icon icon='left-arrow' size='md' />
                </S.Navigation>

                <S.SavedViewName>{ savedViews?.current }</S.SavedViewName>

                <S.Navigation onClick={nextView} title={gettext('Next')} disabled={!hasNext}>
                    <Icon icon='right-arrow' size='md' />
                </S.Navigation>
            </div>

            <S.SideSections className='control-section right'>
                <FullScreen store={store} />
            </S.SideSections>
        </Controller>
    );
};

UnityVGXPresentModeController.propTypes = {
    viewer: PropTypes.object,
    store: PropTypes.object
};

const UnityVGXViewerController = ({ store, viewer }) => {
    const presentationMode = viewer.controller.state.get('presentationMode');
    const enableHome = viewer.controller.state.get('enableHome');

    return presentationMode?.active
        ? <UnityVGXPresentModeController store={store} viewer={viewer} />
        : <Controller store={store}>
            <div className='control-section center left right' style={{ maxWidth: 'unset' }}>
                {
                    viewer && <React.Fragment>
                        <S.Navigation onClick={viewer.controller.goHome} title={gettext('Home')} disabled={!enableHome}>
                            <Icon icon='3d-home' size='md' />
                        </S.Navigation>
                        <Toggler controller={viewer.controller} title={gettext('Palettes')} icon='3d-show-panel' name='palettes' />
                        <Toggler controller={viewer.controller} title={gettext('Edit clip cube')} icon='3d-edit-clipcube' name='clipCube' />
                        <NavigationMode controller={viewer.controller} />
                        <Toggler controller={viewer.controller} title={gettext('Settings')} icon='3d-settings' name='settings' />
                        <Toggler controller={viewer.controller} title={gettext('Help')} icon='help' name='help' />
                    </React.Fragment>
                }
                <FullScreen store={store}/>
            </div>
        </Controller>;
};

UnityVGXViewerController.propTypes = {
    store: PropTypes.object,
    viewer: PropTypes.object
};

export default observer(UnityVGXViewerController);
