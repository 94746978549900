import unorm from 'unorm';

import svgs from '../base/default-file-images';
import validators from './validators';
import { GOOGLE_PAGES_NAMES, parseSharedDriveLookup, previewPath as googlePreviewPath } from '../browser/google-shared-drives';
import { ONE_DRIVE_PAGES_NAMES, previewPath as oneDrivePreviewPath } from '../browser/onedrive-shared-drives';
import { StorageProvider } from '../browser/storage-providers';

const FileTypes = (() => {
    return {
        image: 'image',
        pdf: 'pdf',
        txt: 'txt',
        vwx: 'vwx',
        vwxp: 'vwxp',
        vw3dw: 'vw3dw',
        vgx: 'vgx',
        vgx_views: 'vgx_views',
        vmsr: 'vmsr',
        vcdoc: 'vcdoc',
        panorama: 'panorama',
        heic: 'heic',
        cr2: 'cr2',
        html: 'html',
        folder: 'folder',
        video: 'video',
        video_360: 'video_360',
        pts: 'pts',
        archive: 'archive',
        ckpt: 'ckpt',
        connect_cad: 'connect_cad',
        rvt: 'rvt',
        rfa: 'rfa',
        get (path, isFolder, defaultType) {
            return buildFileType(isFolder ? 'folder' : getFileType(path, defaultType));
        },
        checkExtension (fileName, ext) {
            return fileTypeRegex[ext].test(fileName);
        },
        isFileTypeAllowed (path, allowedTypes) {
            return allowedTypes.includes(FileTypes.get(path).type);
        },
        fromResourceFileType (fileType) {
            const type = {
                HEIC: FileTypes.heic,
                CR2: FileTypes.cr2,
                VWX: FileTypes.vwx,
                PDF: FileTypes.pdf,
                VWXP: FileTypes.vwxp,
                VWXW: FileTypes.vwx,
                IMG: FileTypes.image,
                VW3DW: FileTypes.vw3dw,
                VGX: FileTypes.vgx,
                VGX_VIEWS: FileTypes.vgx_views,
                VCDOC: FileTypes.vcdoc,
                VMSR: FileTypes.vmsr,
                PANORAMA: FileTypes.panorama,
                VIDEO: FileTypes.video,
                VIDEO_360: FileTypes.video_360,
                PTS: FileTypes.pts,
                ARCHIVE: FileTypes.archive,
                CKPT: FileTypes.ckpt,
                CONNECT_CAD: FileTypes.connect_cad,
                RVT: FileTypes.rvt,
                RFA: FileTypes.rfa
            }[fileType];
            return type && buildFileType(type);
        },
        build: buildFileType
    };

    function buildFileType (fileType) {
        return {
            defaultFileTypeIcon: defaultFileTypeIcon.bind(null, fileType),
            type: fileType
        };
    }
})();

const fileTypeRegex = {
    [FileTypes.panorama]: /\.360.jpe?g$/i,
    [FileTypes.image]: /\.(jpe?g$)|(png$)|(gif$)|(bmp$)|(ico$)|(svg$)/i,
    [FileTypes.pdf]: /\.pdf$/i,
    [FileTypes.txt]: /\.txt$/i,
    [FileTypes.vwx]: /\.(vwx$)|(vwxw$)/i,
    [FileTypes.vwxp]: /\.vwxp?$/i,
    [FileTypes.vw3dw]: /\.vw3dw$/i,
    [FileTypes.vgx]: /\.vgx$/i,
    [FileTypes.vgx_views]: /\.savedviews$/i,
    [FileTypes.vmsr]: /\.vmsr$/i,
    [FileTypes.vcdoc]: /\.vcdoc$/i,
    [FileTypes.heic]: /\.(heic$)|(heif$)/i,
    [FileTypes.cr2]: /\.(cr2$)/i,
    [FileTypes.html]: /\.html?$/i,
    [FileTypes.video]: /\.(mp4$)|(avi$)|(mov$)|(ogg$)|(webm$)|(mpe?g$)/i,
    [FileTypes.pts]: /\.pts$/i,
    [FileTypes.archive]: /\.(zip)|(7z)|(rar)/i,
    [FileTypes.ckpt]: /\.ckpt$/i,
    [FileTypes.rvt]: /\.rvt$/i,
    [FileTypes.rfa]: /\.rfa$/i,
    [FileTypes.connect_cad]: /\.connectcad$/i
};

function getFileType (path, defaultType) {
    let type;
    for (type of Object.keys(fileTypeRegex)) {
        if (fileTypeRegex[type].test(path)) {
            return type;
        }
    }
    return defaultType || 'unknown';
}

export const DEFAULT_ICONS = {
    [FileTypes.panorama]: 'file-panorama',
    [FileTypes.image]: 'file-image',
    [FileTypes.heic]: 'file-image',
    [FileTypes.cr2]: 'file-image',
    [FileTypes.pdf]: 'file-pdf',
    [FileTypes.vcdoc]: 'file-vcdoc',
    [FileTypes.txt]: 'file-text',
    [FileTypes.vwx]: 'file-vwx',
    [FileTypes.vgx]: 'file-vgx',
    [FileTypes.vwx3dw]: 'file-vwx',
    [FileTypes.video]: 'file-video',
    [FileTypes.video_360]: 'file-video',
    [FileTypes.pts]: 'file-pts',
    [FileTypes.archive]: 'file-archive',
    [FileTypes.folder]: 'folder',
    [FileTypes.ckpt]: 'ckpt',
    [FileTypes.rvt]: 'file-rvt',
    [FileTypes.rfa]: 'file-rvt',
    [FileTypes.connect_cad]: 'file-text'
};

export function defaultFileTypeFontIcon (fileType) {
    return DEFAULT_ICONS[fileType] || 'file-default';
}

function defaultFileTypeIcon (fileType) {
    return svgs[defaultFileTypeFontIcon(fileType)];
}

function formatBytes (size) {
    if (size > 1000000000) {
        return Math.round(size / 1000000000, 1) + 'GB';
    }
    if (size > 1000000) {
        return Math.round(size / 1000000, 1) + 'MB';
    }
    if (size > 1000) {
        return Math.round(size / 1000, 1) + 'KB';
    }
    return size + 'B';
}

const FilePath = (path, storageType = StorageProvider.S3.storageType) => {
    return {
        name () {
            return unorm.nfc(path.replace(/\/*$/, '').replace(/^.*\//, ''));
        },
        folder () {
            return unorm.nfc(('/' + path.replace(/\/+$/, '')).replace(/\/[^/]+$/, '').replace(/^\/+/, ''));
        },
        baseName (isFolder) {
            return isFolder ? this.name() : unorm.nfc(this.name().replace(/\.[^/.]+$/, ''));
        },
        extension (isFolder) {
            if (isFolder) {
                return '';
            } else {
                const ext = unorm.nfc(/(?:\.([^.]+))?$/.exec(this.name())[1]);
                return ext === '' ? '' : ('.' + ext);
            }
        },
        preview (options = {}) {
            const { ignoreProvider = false } = options;
            const previewPath = storageType === StorageProvider.GOOGLE_DRIVE.storageType
                ? googlePreviewPath(path)
                : storageType === StorageProvider.ONE_DRIVE.storageType
                    ? oneDrivePreviewPath(path)
                    : path;
            return buildPreviewPath([
                ignoreProvider ? '' : StorageProvider.fromStorageType(storageType).displayName,
                ...previewPath.split('/')
            ]);
        },
        isAllowedFileName () {
            return /^[^ \\?<>:*|%#&"\~][^\\?<>:*|%#&"\~]*[^ ]$/.test(path) && !FilePath(path).baseName().endsWith(' '); // eslint-disable-line
        },
        isAllowedDirectoryPath () {
            return this.name().split('/').every(word => {
                const wordParts = word.split('.');
                const ext = wordParts[wordParts.length - 1];
                const name = wordParts.length > 1
                    ? wordParts.slice(0, wordParts.length - 1).join(' ')
                    : wordParts[0];
                return word === ''
                    ? true
                    : ext === ''
                        ? false
                        : validators.folderName(name) &&
                            validators.invalidName(name) &&
                            validators.folderName(ext) &&
                            validators.invalidName(ext);
            }
            );
        },
        isExtensionAllowed (regexArray) {
            return !!regexArray.some(regex => this.extension().match(regex));
        },
        _breadcrumbs (pathPrefix = '') {
            pathPrefix = (pathPrefix !== '') ? (pathPrefix.replace(/\/+$/, '') + '/') : '';
            const parts = path.split('/').filter(part => part !== '');
            return parts
                .reduce((paths, part) => {
                    const last = paths[paths.length - 1];
                    paths.push([part, (last ? last[1] + '/' : '') + part]);
                    return paths;
                }, [])
                .map(([name, path]) => [name, pathPrefix + path]);
        },
        breadcrumbs (...args) {
            const result = this._breadcrumbs(...args);
            if (storageType === StorageProvider.GOOGLE_DRIVE.storageType) {
                const driveId = parseSharedDriveLookup(path)[0];
                if (!driveId) {
                    result.unshift([GOOGLE_PAGES_NAMES.MY_DRIVE.name, '']);
                } else {
                    result[0][0] = googlePreviewPath(result[0][0]);
                }
            }

            if (storageType === StorageProvider.ONE_DRIVE.storageType) {
                const driveId = parseSharedDriveLookup(path)[0];
                if (!driveId) {
                    result.unshift([ONE_DRIVE_PAGES_NAMES.MY_DRIVE.name, '']);
                } else {
                    result[0][0] = oneDrivePreviewPath(result[0][0]);
                }
            }

            return result;
        },
        removePrefix (prefix) {
            return path.startsWith(prefix) ? path.substr(prefix.length).replace(/^\/+/, '') : path;
        },
        replacePrefix (oldPrefix, newPrefix) {
            return path.startsWith(oldPrefix)
                ? `${newPrefix}/${this.removePrefix(oldPrefix)}`.replace(/^\/+/, '')
                : path;
        }
    };
};

class File {
    static fromPath (path, size) {
        const filePath = FilePath(path);
        return new File(filePath.name(), filePath.folder(), size);
    }

    constructor (name, folder, size = null) {
        this.name = name;
        this.folder = folder;
        this.path = this.folder.length > 0 ? this.folder.replace(/\/*$/, '') + '/' + this.name : this.name;
        this.size = (isNaN(size) || size === null) ? 0.0 : parseFloat(size);
        this.type = FileTypes.get(this.name);
    }

    formatedPath () {
        return this.path.replace(/\//g, ' / ');
    }

    formatedSize () {
        return formatBytes(this.size);
    }

    contentType () {
        const contentTypes = {
            323: 'text/h323',
            '*': 'application/octet-stream',
            acx: 'application/internet-property-stream',
            ai: 'application/postscript',
            aif: 'audio/x-aiff',
            aifc: 'audio/x-aiff',
            aiff: 'audio/x-aiff',
            asf: 'video/x-ms-asf',
            asr: 'video/x-ms-asf',
            asx: 'video/x-ms-asf',
            au: 'audio/basic',
            avi: 'video/x-msvideo',
            axs: 'application/olescript',
            bas: 'text/plain',
            bcpio: 'application/x-bcpio',
            bin: 'application/octet-stream',
            bmp: 'image/bmp',
            c: 'text/plain',
            cat: 'application/vnd.ms-pkiseccat',
            cdf: 'application/x-netcdf',
            cer: 'application/x-x509-ca-cert',
            class: 'application/octet-stream',
            clp: 'application/x-msclip',
            cmx: 'image/x-cmx',
            cod: 'image/cis-cod',
            cpio: 'application/x-cpio',
            crd: 'application/x-mscardfile',
            crl: 'application/pkix-crl',
            crt: 'application/x-x509-ca-cert',
            csh: 'application/x-csh',
            css: 'text/css',
            dcr: 'application/x-director',
            der: 'application/x-x509-ca-cert',
            dir: 'application/x-director',
            dll: 'application/x-msdownload',
            dms: 'application/octet-stream',
            doc: 'application/msword',
            dot: 'application/msword',
            dvi: 'application/x-dvi',
            dxr: 'application/x-director',
            eps: 'application/postscript',
            etx: 'text/x-setext',
            evy: 'application/envoy',
            exe: 'application/octet-stream',
            fif: 'application/fractals',
            flr: 'x-world/x-vrml',
            gif: 'image/gif',
            gtar: 'application/x-gtar',
            gz: 'application/x-gzip',
            h: 'text/plain',
            hdf: 'application/x-hdf',
            hlp: 'application/winhlp',
            hqx: 'application/mac-binhex40',
            hta: 'application/hta',
            htc: 'text/x-component',
            htm: 'text/html',
            html: 'text/html',
            htt: 'text/webviewhtml',
            ico: 'image/x-icon',
            ief: 'image/ief',
            iii: 'application/x-iphone',
            ins: 'application/x-internet-signup',
            isp: 'application/x-internet-signup',
            jfif: 'image/pipeg',
            jpe: 'image/jpeg',
            jpeg: 'image/jpeg',
            jpg: 'image/jpeg',
            js: 'application/x-javascript',
            latex: 'application/x-latex',
            lha: 'application/octet-stream',
            lsf: 'video/x-la-asf',
            lsx: 'video/x-la-asf',
            lzh: 'application/octet-stream',
            m13: 'application/x-msmediaview',
            m14: 'application/x-msmediaview',
            m3u: 'audio/x-mpegurl',
            man: 'application/x-troff-man',
            mdb: 'application/x-msaccess',
            me: 'application/x-troff-me',
            mht: 'message/rfc822',
            mhtml: 'message/rfc822',
            mid: 'audio/mid',
            mny: 'application/x-msmoney',
            mov: 'video/quicktime',
            movie: 'video/x-sgi-movie',
            mp2: 'video/mpeg',
            mp3: 'audio/mpeg',
            mp4: 'video/mp4',
            mpa: 'video/mpeg',
            mpe: 'video/mpeg',
            mpeg: 'video/mpeg',
            mpg: 'video/mpeg',
            mpp: 'application/vnd.ms-project',
            mpv2: 'video/mpeg',
            ms: 'application/x-troff-ms',
            msg: 'application/vnd.ms-outlook',
            mvb: 'application/x-msmediaview',
            nc: 'application/x-netcdf',
            nws: 'message/rfc822',
            oda: 'application/oda',
            p10: 'application/pkcs10',
            p12: 'application/x-pkcs12',
            p7b: 'application/x-pkcs7-certificates',
            p7c: 'application/x-pkcs7-mime',
            p7m: 'application/x-pkcs7-mime',
            p7r: 'application/x-pkcs7-certreqresp',
            p7s: 'application/x-pkcs7-signature',
            pbm: 'image/x-portable-bitmap',
            pdf: 'application/pdf',
            pfx: 'application/x-pkcs12',
            pgm: 'image/x-portable-graymap',
            pko: 'application/ynd.ms-pkipko',
            pma: 'application/x-perfmon',
            pmc: 'application/x-perfmon',
            pml: 'application/x-perfmon',
            pmr: 'application/x-perfmon',
            pmw: 'application/x-perfmon',
            pnm: 'image/x-portable-anymap',
            png: 'image/png',
            pot: 'application/vnd.ms-powerpoint',
            ppm: 'image/x-portable-pixmap',
            pps: 'application/vnd.ms-powerpoint',
            ppt: 'application/vnd.ms-powerpoint',
            prf: 'application/pics-rules',
            ps: 'application/postscript',
            pub: 'application/x-mspublisher',
            qt: 'video/quicktime',
            ra: 'audio/x-pn-realaudio',
            ram: 'audio/x-pn-realaudio',
            ras: 'image/x-cmu-raster',
            rgb: 'image/x-rgb',
            rmi: 'audio/mid',
            roff: 'application/x-troff',
            rtf: 'application/rtf',
            rtx: 'text/richtext',
            scd: 'application/x-msschedule',
            sct: 'text/scriptlet',
            setpay: 'application/set-payment-initiation',
            setreg: 'application/set-registration-initiation',
            sh: 'application/x-sh',
            shar: 'application/x-shar',
            sit: 'application/x-stuffit',
            snd: 'audio/basic',
            spc: 'application/x-pkcs7-certificates',
            spl: 'application/futuresplash',
            src: 'application/x-wais-source',
            sst: 'application/vnd.ms-pkicertstore',
            stl: 'application/vnd.ms-pkistl',
            stm: 'text/html',
            sv4cpio: 'application/x-sv4cpio',
            sv4crc: 'application/x-sv4crc',
            svg: 'image/svg+xml',
            swf: 'application/x-shockwave-flash',
            t: 'application/x-troff',
            tar: 'application/x-tar',
            tcl: 'application/x-tcl',
            tex: 'application/x-tex',
            texi: 'application/x-texinfo',
            texinfo: 'application/x-texinfo',
            tgz: 'application/x-compressed',
            tif: 'image/tiff',
            tiff: 'image/tiff',
            tr: 'application/x-troff',
            trm: 'application/x-msterminal',
            tsv: 'text/tab-separated-values',
            txt: 'text/plain',
            uls: 'text/iuls',
            ustar: 'application/x-ustar',
            vcf: 'text/x-vcard',
            vrml: 'x-world/x-vrml',
            wav: 'audio/x-wav',
            webm: 'video/webm',
            wcm: 'application/vnd.ms-works',
            wdb: 'application/vnd.ms-works',
            wks: 'application/vnd.ms-works',
            wmf: 'application/x-msmetafile',
            wps: 'application/vnd.ms-works',
            wri: 'application/x-mswrite',
            wrl: 'x-world/x-vrml',
            wrz: 'x-world/x-vrml',
            xaf: 'x-world/x-vrml',
            xbm: 'image/x-xbitmap',
            xla: 'application/vnd.ms-excel',
            xlc: 'application/vnd.ms-excel',
            xlm: 'application/vnd.ms-excel',
            xls: 'application/vnd.ms-excel',
            xlt: 'application/vnd.ms-excel',
            xlw: 'application/vnd.ms-excel',
            xof: 'x-world/x-vrml',
            xpm: 'image/x-xpixmap',
            xwd: 'image/x-xwindowdump',
            z: 'application/x-compress',
            zip: 'application/zip',
            xml: 'application/octet-stream'
        };
        const extension = /(?:\.([^.]+))?$/.exec(this.path)[1];
        return (extension && contentTypes[extension.toLowerCase()]) || 'application/octet-stream';
    }
}

function buildPreviewPath (parts) {
    return parts
        .map(p => p.replace(/^\/+/, '').replace(/\/+$/, ''))
        .filter(p => !!p)
        .join('/')
        .replace(/\//g, ' / ');
}

function buildPath (parts) {
    return '/' + parts
        .filter(p => !!p)
        .map(p => p.replace(/^\/+/, '').replace(/\/+$/, ''))
        .filter(p => !!p)
        .join('/') + '/';
}

export {
    FileTypes,
    FilePath,
    File,
    buildPreviewPath,
    buildPath,
    formatBytes
};
